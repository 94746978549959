export const getCustomHeightFromN = (n: number) => {
  let height: number | undefined = 150;
  if (n > 5) {
    height = undefined;
  } else if (n > 4) {
    height = 400;
  } else if (n > 3) {
    height = 300;
  } else if (n > 2) {
    height = 250;
  } else if (n > 1) {
    height = 200;
  }
  return height;
};
