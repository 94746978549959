import { getDataBatches } from "@/features/OverallLineEfficiency/utils/getDataBatches";
import { useGetStatesLog } from "../api/useGetStatesLog";
import { LineStatesLog } from "./LineStatesLog";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const LineStatesLogHandler = ({
  line_id,
  view_mode,
  has_view_from_first_product,
}: {
  line_id: number;
  view_mode: "automatic" | "manual";
  has_view_from_first_product: boolean;
}) => {
  const { data, isLoading, error } = useGetStatesLog({
    line_id,
    view_mode,
    has_view_from_first_product,
  });

  if (isLoading) {
    return <SkeletonCard />;
  }

  if (error) {
    return <div>an error occurred</div>;
  }

  if (
    !data ||
    Object.keys(data).length === 0 ||
    Object.keys(Object.keys(data)[0]).length === 0
  ) {
    return <div>No data</div>;
  }

  const batches = getDataBatches(data.data);

  if (
    Object.keys(batches).length === 0 ||
    Object.keys(Object.keys(batches)[0]).length === 0
  ) {
    return <div>No data</div>;
  }

  return <LineStatesLog dataBatches={batches} order={data.order || []} />;
};
