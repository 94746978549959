import { CustomChart } from "@/components/highcharts/customchart/CustomChart";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { renderToString } from "react-dom/server";
import { AlarmSchema, useGetTopAlarms } from "../api/useGetTopAlarms";

export const LineTopAlarmsLive = ({
  line_id,
  config,
}: {
  line_id: number;
  config: "automatic" | "manual";
}) => {
  const { data } = useGetTopAlarms({ line_id, config });
  if (!data) return null;
  return <LineTopAlarms topAlarms={data.topAlarms} />;
};

export const LineTopAlarms = ({ topAlarms }: { topAlarms: AlarmSchema[] }) => {
  const translate = useTranslate();
  if (!topAlarms) return null;
  const categories = topAlarms.map((alarm) => alarm.alarmInfo);
  const occurrences = topAlarms.map((alarm) => alarm.occurences);
  const durationInMinutes = topAlarms.map((alarm) => ({
    y: alarm.durationInSeconds / 60,
    custom: {
      duration: alarm.durationInSeconds,
      description: alarm.alarmDescription,
      occurrences: alarm.occurences,
    },
  }));
  return (
    <Card>
      <CustomChart
        title={translate("overall_line_efficiency.top_ten_alarms")}
        xAxisType="category"
        xAxisCategories={categories}
        series1={{
          type: "column",
          yAxis: {
            labels: { style: { color: "white" } },
            title: {
              text: translate("overall_line_efficiency.duration_in_minutes"),
              style: { color: "white" },
            },
          },
          series: {
            type: "column",
            name: translate("duration"),
            borderColor: "transparent",
            color: "red",
            data: durationInMinutes,
          },
        }}
        series2={{
          type: "line",
          yAxis: {
            labels: { style: { color: "white" } },
            title: {
              text: translate("occurrences"),
              style: { color: "white" },
            },
            gridLineColor: "transparent",
            opposite: true,
          },
          series: {
            type: "line",
            name: translate("occurrences"),
            zIndex: 10,
            color: "#F7B500",
            data: occurrences,
          },
        }}
        legend={{ enabled: true }}
        tooltip={{
          enabled: true,
          options: {
            useHTML: true,
            formatter: function () {
              const point = this.points?.[0].point as unknown as {
                custom: {
                  duration: number;
                  description: string;
                  occurrences: number;
                };
              };
              const category = this.points?.[0].point.category;
              if (point) {
                return renderToString(
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <b>{category}:</b>
                    <b>
                      {`${translate("duration")}: `}
                      {TimeHelpers.parseDurationToString({
                        duration: point.custom.duration * 1000,
                        fmt: "mm'm' ss's'",
                      })}
                    </b>
                    <b>
                      {translate("occurrences")}: {point.custom.occurrences}
                    </b>
                    <b>
                      {translate("description")}: {point.custom.description}
                    </b>
                  </div>,
                );
              }
            },
          },
        }}
      />
    </Card>
  );
};
