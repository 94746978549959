import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { useGetStatesLogData } from "@/features/StopJustification/api/useGetStatesLogData";
import { parseStatesLogData } from "@/features/StopJustification/utils/parseStatesLogData";
import { useTranslate } from "@/i18n/config";

export const StatesLog = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetStatesLogData();

  if (isLoading) return <SkeletonCard height={400} />;

  if (error) return <div>{translate("user_feedback.an_error_occurred")}</div>;

  if (!data) return <div>{translate("user_feedback.no_data")}</div>;

  const { categories, chartData } = parseStatesLogData(data.data);

  return (
    <XRangeChart
      categories={categories}
      data={chartData}
      title=""
      labelsWidth={150}
    />
  );
};
