/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, LinearProgress, Stack, styled } from "@mui/material";
import { motion } from "framer-motion";

import { ContainerLayout } from "@/features/LandingPage/components/ContainerLayout";

export const states: {
  name: string;
  color: string;
  amount: number;
  total: number;
}[] = [
  {
    amount: 1,
    total: 1,
    name: "Producing",
    color: "#00FF00",
  },
  {
    amount: 0,
    total: 1,
    name: "Not Producing",
    color: "#ff7a0d",
  },
  {
    amount: 0,
    total: 1,
    name: "Scheduled Downtime",
    color: "#8a8a8a",
  },
  {
    amount: 0,
    total: 1,
    name: "Idle",
    color: "#eeeeee",
  },
];

const CategoryContainer = styled(ContainerLayout)({
  cursor: "pointer",
});

const generateRatioText = (amount: number = 0, total: number = 0) => {
  if (total === 0) {
    return "0%";
  } else {
    return `${Math.round((amount / total) * 100)}%`;
  }
};

const generateActiveStateBorder = (isActive: boolean, color: string) => {
  if (isActive) {
    return `2px solid ${color}`;
  } else {
    return "2px solid #00000000";
  }
};

export const LineStateCategory = ({
  total,
  idleAmount,
  producingAmount,
  notProducingAmount,
  downtimeAmount,
  activeState,
  notConfiguredAmount,
  onStateSelected,
}: {
  total: number;
  idleAmount: number;
  producingAmount: number;
  notProducingAmount: number;
  downtimeAmount: number;
  notConfiguredAmount: number;
  activeState: string | null;
  onStateSelected: (state: string) => void;
}) => {
  return (
    <>
      <Stack
        gap={2}
        direction="row"
        justifyContent="space-around"
        flexWrap="wrap"
      >
        <CategoryContainer
          component={motion.div}
          flex={1}
          onClick={() => onStateSelected("Producing")}
          // @ts-ignore
          whileHover={{ scale: 1.02 }}
          sx={{
            color: "#00FF00",
            minWidth: "10rem",
            fontweight: "bold",
            border: generateActiveStateBorder(
              activeState === "Producing",
              "#00FF00",
            ),
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="column"
          >
            <Box sx={{ fontWeight: "bold" }}>Producing</Box>
            <Box>
              {producingAmount}/{total}
            </Box>
            <Box>{generateRatioText(producingAmount, total)}</Box>
          </Stack>
          <LinearProgress
            color="inherit"
            variant="determinate"
            value={(producingAmount / total) * 100}
          />
        </CategoryContainer>

        <CategoryContainer
          component={motion.div}
          flex={1}
          onClick={() => onStateSelected("Not Producing")}
          // @ts-ignore
          whileHover={{ scale: 1.02 }}
          sx={{
            color: "#ff7a0d",
            minWidth: "10rem",
            fontweight: "bold",
            border: generateActiveStateBorder(
              activeState === "Not Producing",
              "#ff7a0d",
            ),
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="column"
          >
            <Box sx={{ fontWeight: "bold" }}>Not Producing</Box>
            <Box>
              {notProducingAmount}/{total}
            </Box>
            <Box>{generateRatioText(notProducingAmount, total)}</Box>
          </Stack>
          <LinearProgress
            color="inherit"
            variant="determinate"
            value={(notProducingAmount / total) * 100}
          />
        </CategoryContainer>

        <CategoryContainer
          component={motion.div}
          flex={1}
          onClick={() => onStateSelected("Scheduled Downtime")}
          // @ts-ignore
          whileHover={{ scale: 1.02 }}
          sx={{
            color: "#8a8a8a",
            minWidth: "10rem",
            fontweight: "bold",
            border: generateActiveStateBorder(
              activeState === "Scheduled Downtime",
              "#8a8a8a",
            ),
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="column"
          >
            <Box sx={{ fontWeight: "bold" }}>Scheduled Downtime</Box>
            <Box>
              {downtimeAmount}/{total}
            </Box>
            <Box>{generateRatioText(downtimeAmount, total)}</Box>
          </Stack>
          <LinearProgress
            color="inherit"
            variant="determinate"
            value={(downtimeAmount / total) * 100}
          />
        </CategoryContainer>

        <CategoryContainer
          component={motion.div}
          flex={1}
          onClick={() => onStateSelected("Idle")}
          // @ts-ignore
          whileHover={{ scale: 1.02 }}
          sx={{
            color: "#eeeeee",
            minWidth: "10rem",
            fontweight: "bold",
            border: generateActiveStateBorder(
              activeState === "Idle",
              "#eeeeee",
            ),
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="column"
          >
            <Box sx={{ fontWeight: "bold" }}>Idle</Box>
            <Box>
              {idleAmount}/{total}
            </Box>
            <Box>{generateRatioText(idleAmount, total)}</Box>
          </Stack>
          <LinearProgress
            color="inherit"
            variant="determinate"
            value={(idleAmount / total) * 100}
          />
        </CategoryContainer>
        <CategoryContainer
          component={motion.div}
          flex={1}
          onClick={() => onStateSelected("Not Configured")}
          // @ts-ignore
          whileHover={{ scale: 1.02 }}
          sx={{
            color: "#800080",
            minWidth: "10rem",
            fontweight: "bold",
            border: generateActiveStateBorder(
              activeState === "Not Configured",
              "#800080",
            ),
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="column"
          >
            <Box sx={{ fontWeight: "bold" }}>Not Configured</Box>
            <Box>
              {notConfiguredAmount}/{total}
            </Box>
            <Box>{generateRatioText(notConfiguredAmount, total)}</Box>
          </Stack>
          <LinearProgress
            color="inherit"
            variant="determinate"
            value={(notConfiguredAmount / total) * 100}
          />
        </CategoryContainer>
      </Stack>
    </>
  );
};
