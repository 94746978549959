import {
  Button,
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { z } from "zod";
import { Line } from "../../api/useGetUserProductionLines";
import {
  HistoricTimeFrame,
  useGetHistoricLineList,
} from "./api/useGetHistoricLineList";

import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import { NavButton } from "@/components/NavButton";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { ArrowBackOutlined } from "@mui/icons-material";
import { ItemCard } from "@/components/ItemCard";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { AnimatePresence, motion } from "framer-motion";
import { RangePicker } from "@/components/Calendars/RangePicker";
import { useGetHistoricLineData } from "./api/useGetHistoricLineData";
import { RadialDoubleBarGaugeLayout } from "@/features/BatchNavigator/components/RadialGaugeLayout";
import { GaugeLayout } from "@/features/MachineDetail/components/GaugeLayout";
import Grid2 from "@mui/material/Unstable_Grid2";
import { PlantDesign } from "../../components/PlantDesign";
import { ProductsHistogram } from "../LineDetail/components/ProductsHistogram";
import { TotalProductsSummary } from "../LineDetail/components/TotalProductsSummary";
import { colors } from "@/styles/colors";
import { LineEfficiencyTrend } from "../../components/LineEfficiencyTrend";
import { LineProductsProjection } from "../../components/LineProductsProjection";
import { Card } from "@/components/Layout/Card";
import { HistoricLineMachinesStatesLog } from "./components/HistoricLineMachinesStatesLog";
import { getDataBatches } from "../../utils/getDataBatches";
import { TogglePerformanceView } from "../../components/TogglePerformanceView";
import { HistoricConfigurationModal } from "./components/HistoricConfigurationModal";
import { LineLastProductionHistoric } from "../LineDetail/components/LineLastProductions";
import { LineTopAlarms } from "../LineDetail/components/LineTopAlarms";
import { AlternativeViewSwitch } from "../../components/AlternativeViewSwitch";
import { HistoricLineSelectedFrame } from "./context/historic-line-selected-frame";

const PageState = z.object({
  line: Line,
  view_mode: z.enum(["automatic", "manual"]),
});

type PageState = z.infer<typeof PageState>;

const HistoricLineDetail = ({ state }: { state: PageState }) => {
  const { line, view_mode } = state;

  const [selectedPeriod, setSelectedPeriod] =
    useState<HistoricTimeFrame | null>(null);

  const [view, setView] = useState(view_mode);

  const { data } = useGetHistoricLineList({
    config: view,
    id: line.line_id,
  });

  const list = useMemo(() => data?.data || [], [data]);

  useEffect(() => {
    setSelectedPeriod(null);
  }, [list]);

  return (
    <>
      <OverviewNavBar>
        <ToggleButtonGroup
          color="primary"
          value={view}
          exclusive
          size="small"
          onChange={(_, value) => setView(value)}
          aria-label="line view mode"
        >
          <ToggleButton size="small" value="automatic">
            Automatic
          </ToggleButton>
          <ToggleButton size="small" value="manual">
            Manual
          </ToggleButton>
        </ToggleButtonGroup>
        {line.has_view_from_first_product ? <AlternativeViewSwitch /> : null}
        {view === "automatic" ? (
          <SelectTimeSelection
            exclude={[
              "Custom",
              "Month",
              "SixMonths",
              "ThreeMonths",
              "Year",
              "TwoWeeks",
            ]}
            forceCalendar
          />
        ) : (
          <RangePicker onPickDate={() => {}} />
        )}
        {selectedPeriod ? (
          <Button size="small" onClick={() => setSelectedPeriod(null)}>
            Select another period
          </Button>
        ) : null}

        <Box
          sx={{
            marginLeft: "auto",
          }}
        >
          <NavButton
            to="/overview/overall-line-efficiency/line-detail"
            text="back"
            startIcon={<ArrowBackOutlined />}
            state={{ line, view_mode }}
            replace
          />
        </Box>
      </OverviewNavBar>
      {selectedPeriod ? (
        <HistoricLineDetailPage
          frame={selectedPeriod}
          line={line}
          view={view_mode}
        />
      ) : (
        <Stack spacing={2}>
          <Typography variant="h6" fontWeight="bold">
            Please select a period
          </Typography>
          <AnimatePresence presenceAffectsLayout mode="wait">
            <motion.div
              key={JSON.stringify(list)}
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="show"
            >
              <Grid container spacing={2}>
                {list.map((item) => (
                  <Grid item xs={6} md={3} key={item.id}>
                    <ItemCard
                      item={item}
                      onClick={() => setSelectedPeriod(item)}
                      subtitle={TimeHelpers.parseTimestampToString({
                        timestamp: item.dateStart,
                        fmt: "y LLL dd HH:mm:ss ",
                      })}
                      title={TimeHelpers.parseTimestampToString({
                        timestamp: item.dateStart,
                        fmt: "y LLL dd ",
                      })}
                      optionalLabel={item.timeLapse}
                    />
                  </Grid>
                ))}
              </Grid>
            </motion.div>
          </AnimatePresence>
        </Stack>
      )}
    </>
  );
};

const HistoricLineDetailPage = ({
  frame,
  line,
}: {
  frame: HistoricTimeFrame;
  line: Line;
  view: "automatic" | "manual";
}) => {
  const {
    data: historicLineData,
    isLoading,
    isError,
  } = useGetHistoricLineData({
    id: frame.id,
    has_view_from_first_product: !!line.has_view_from_first_product,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error</div>;
  }
  if (!historicLineData) {
    return <div>No data</div>;
  }

  const {
    configuration,
    data,
    line_efficiencies,
    products_projection,
    states_log,
    order,
    line_production,
    top_ten_alarms,
  } = historicLineData.data;

  const statesLogBatches = getDataBatches(states_log);

  return (
    <Stack gap={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
        }}
      >
        <Button variant="contained" disabled>
          {line.factory_name} - {line.line_name}
        </Button>

        <Box
          sx={{
            marginLeft: "auto",
          }}
        >
          <Typography display="inline-block" variant="body2">
            Configurations
          </Typography>
          <HistoricConfigurationModal configuration={configuration} />
        </Box>
      </Box>
      <Grid2 container spacing={2}>
        <Grid2 xs={4} md={5}>
          <Stack gap={5} direction="column" justifyContent="center">
            <Stack gap={2} direction="row">
              <GaugeLayout
                dimension="large"
                color={colors.kpi.oee}
                min={0}
                max={100}
                type="percentage"
                title="OEE"
                value={data.line_efficiency}
              />

              <RadialDoubleBarGaugeLayout
                color=""
                realValue={data.products_gap.current_products}
                targetValue={data.products_gap.ideal_current_products}
                value={parseInt(
                  (
                    data.products_gap.current_products -
                    data.products_gap.ideal_current_products
                  ).toFixed(0),
                  10,
                )}
                max={data.products_gap.total}
                title={
                  data.products_gap.current_products <
                  data.products_gap.ideal_current_products
                    ? "Delay"
                    : "Advanced"
                }
                dimension="large"
              />
            </Stack>

            <TotalProductsSummary
              output_products={data.output_products}
              output_wastes={data.output_wastes}
              products={data.products}
            />
          </Stack>
        </Grid2>
        <Grid2 xs={7} md={7}>
          <LineLastProductionHistoric historicData={line_production} />
        </Grid2>
      </Grid2>

      <Grid2>
        <LineTopAlarms topAlarms={top_ten_alarms} />
      </Grid2>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          {line.line_configuration ? (
            <Card>
              <Stack
                gap={2}
                direction="row"
                paddingBottom={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1">
                  {line.factory_name} - {line.line_name}
                </Typography>
                <TogglePerformanceView />
              </Stack>

              <HistoricLineSelectedFrame.Provider value={frame}>
                <PlantDesign config={line.line_configuration} historicNodes />
              </HistoricLineSelectedFrame.Provider>
            </Card>
          ) : null}
        </Grid2>
        {Object.keys(statesLogBatches).length > 0 &&
        Object.keys(Object.keys(statesLogBatches)[0]).length > 0 ? (
          <Grid2 xs={12}>
            <HistoricLineMachinesStatesLog
              dataBatches={statesLogBatches}
              order={order || []}
            />
          </Grid2>
        ) : (
          <div>No data</div>
        )}
        <Grid2 xs={12}>
          <LineEfficiencyTrend
            data={line_efficiencies.map((value) => ({
              category: value.machine,
              values: value.values,
            }))}
          />
        </Grid2>
        <Grid2 xs={6}>
          <LineProductsProjection
            machine={products_projection.machine_output}
            products={products_projection.products}
            products_projection={products_projection.products_projection}
          />
        </Grid2>
        <Grid2 xs={6}>
          <Card>
            <ProductsHistogram
              data={data.products.map((value) => ({
                category: value.machine,
                products: value.products,
                wastes: value.wastes,
              }))}
            />
          </Card>
        </Grid2>
      </Grid2>
    </Stack>
  );
};

export const HistoricPage = () => {
  const location = useLocation();
  const data = location.state as {
    line: Line;
    view_mode: "automatic" | "manual";
  };

  if (!data) {
    return <Navigate to="/overview/overall-line-efficiency" replace />;
  }

  const result = PageState.safeParse(data);

  if (!result.success) {
    return <Navigate to="/overview/overall-line-efficiency" replace />;
  }
  return <HistoricLineDetail state={result.data} />;
};
